export const appCdnURL = "https://staticdu88.win"

export const appHead = {"meta":[{"charset":"utf-8"},{"name":"google","content":"notranslate"},{"name":"robots","content":"index,follow"},{"http-equiv":"Content-Language","content":"vi"},{"name":"og:site_name","content":"Nhà cái DU88"},{"name":"viewport","content":"width=device-width, initial-scale=1, maximum-scale=1.0, viewport-fit=cover, user-scalable=no"}],"link":[{"rel":"icon","type":"image/x-icon","href":"https://staticdu88.win/assets/brand/favicon.ico"},{"rel":"apple-touch-icon","sizes":"180x180","href":"https://staticdu88.win/assets/brand/apple-touch-icon.png"},{"rel":"apple-touch-icon","sizes":"120x120","href":"https://staticdu88.win/assets/brand/apple-touch-icon-120x120.png"},{"rel":"apple-touch-icon","sizes":"120x120","href":"https://staticdu88.win/assets/brand/apple-touch-icon-120x120-precomposed.png"},{"rel":"apple-touch-icon","sizes":"152x152","href":"https://staticdu88.win/assets/brand/apple-touch-icon-152x152-precomposed.png"},{"rel":"apple-touch-icon","sizes":"152x152","href":"https://staticdu88.win/assets/brand/apple-touch-icon-152x152.png"},{"rel":"apple-touch-icon-precomposed","href":"https://staticdu88.win/assets/brand/apple-touch-icon-precomposed.png"}],"style":[],"script":[{"src":"https://demo.nanocosmos.de/nanoplayer/api/release/nanoplayer.4.min.js","type":"text/javascript","defer":true},{"innerHTML":"(function(h,o,t,j,a,r){\n          h.hj=h.hj||function(){(h.hj.q=h.hj.q||[]).push(arguments)};\n          h._hjSettings={hjid:6367132,hjsv:6};\n          a=o.getElementsByTagName('head')[0];\n          r=o.createElement('script');r.async=1;\n          r.src=t+h._hjSettings.hjid+j+h._hjSettings.hjsv;\n          a.appendChild(r);\n          })(window,document,'https://static.hotjar.com/c/hotjar-','.js?sv=');","tagPosition":"head"}],"noscript":[],"htmlAttrs":{"lang":"vi"}}

export const appBaseURL = "/"

export const appBuildAssetsDir = "/_nuxt/"

export const appLayoutTransition = false

export const appPageTransition = false

export const appKeepalive = false

export const appRootId = "__nuxt"

export const appRootTag = "div"

export const renderJsonPayloads = false

export const devPagesDir = null